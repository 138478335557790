<template>
  <div
    :id="placementId"
    data-pure="false"
    :data-slot_id="id"
    class="flex items-center justify-center w-full h-full"
  >
    <div
      :id="id"
      class="relative rm-adslot--moca"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  adSlot: AdSlot<'moca'>
}>()

const id = computed(() => `moca-${props.adSlot.moca_id}`)
const placementId = computed(() => `rm-adslot-${props.adSlot.id}`)

const { getProvider } = useAd()
const provider = getProvider('moca')

const providerUrl = provider?.url
const providerId = provider?.id

const adId = computed(() => {
  return `${providerId}_${props.adSlot.moca_id}`
})

useScript({
  src: `${providerUrl}/?id=${adId.value}`,
  crossorigin: undefined,
}, {
  trigger: consentGiven,
  tagPriority: 'low',
})
</script>

<style scoped>
.vodl-ad__bigsizebanner {
  @apply flex items-center justify-center w-full h-full;
}

.vodl-ad__halfpagead {
  @apply block h-auto absolute w-1/3 left-[101%] max-w-80;

  div {
    @apply fixed top-0;
  }
}

.vodl-ad__skyscraper-left {
  @apply hidden fixed left-0 top-0 text-right;
  width: calc((100% - 1024px)/2); /* 1024px is the max width of the page, we calculate the half because the site is centerd. */
}

@media (min-width: 1624px) {
  .vodl-ad__skyscraper-left {
    @apply block;
  }
}
</style>
